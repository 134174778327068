// Newsletter Signup Form Label Animation on Focus

jQuery('.newsletter-signup form [type=text]').focusin( function() {
	jQuery(this).parent().parent().addClass('has-focus');
});

jQuery('.newsletter-signup form [type=text]').blur( function() {
	if( jQuery(this).val().length < 1) {
		jQuery(this).parent().parent().removeClass('has-focus');
	}
});


document.addEventListener('DOMContentLoaded', function(event) {
	window.addEventListener('load', function() {
		

		if( document.querySelector('.sub-page-banner') ) {
			gsap.to('html', {'--afterWidth': 191, duration: 0.5, ease: 'back.out(4)'});
		}
		
		//gsap.from('#main-nav > li', {y: -200, autoAlpha: 0, ease: 'back', duration: .5, stagger: 0.15});
	});
});









document.addEventListener('DOMContentLoaded', function() {
	const tabContentContainers = document.querySelectorAll('.mm-tab-content');
  
	tabContentContainers.forEach(container => {
	  const tabPanels = container.querySelectorAll('.tabs-panel');
	  let tallestHeight = 0;
  
	  // Temporarily show each tab off-screen to calculate its height
	  tabPanels.forEach(panel => {
		panel.style.position = 'absolute';
		panel.style.visibility = 'hidden';
		panel.style.display = 'block';
  
		const panelHeight = panel.clientHeight;
		if (panelHeight > tallestHeight) {
		  tallestHeight = panelHeight;
		}
  
		// Reset styles after height calculation
		panel.style.position = '';
		panel.style.visibility = '';
		panel.style.display = '';
	  });
  
	  // Set the same height for all tab panels
	  tabPanels.forEach(panel => {
		panel.style.height = `${tallestHeight}px`;
	  });
	});
  });

 


// Reset tabs after function
// to be used on mouseenter and mouseleave function on mega menu script
const solutionsMM = document.querySelector('.mm-solutions');
const tabs = solutionsMM.querySelector('.tabs');
const panels = solutionsMM.querySelector('.tabs-content');
const firstTab = solutionsMM.querySelector('.tabs li:first-child');
const firstTabLink = firstTab.querySelector('a');
const firstPanel = panels.querySelector('.tabs-panel:first-child');

function resetTabs() {
	const activeTab = tabs.querySelector('.is-active');
	const activeTabLink = activeTab.querySelector('a');
	const activePanel = panels.querySelector('.is-active');

	activeTab.classList.remove('is-active');
	activePanel.classList.remove('is-active');
	activeTabLink.ariaSelected = "false";
	activePanel.ariaHidden = "false";

	firstTab.classList.add('is-active');
	firstTabLink.ariaSelected = "true";
	firstPanel.classList.add('is-active');
	firstPanel.ariaHidden = "true";
}




// Keep track of whether the mouse is within the mega menu area
let isMegaMenuAreaHovered = false;
let isMegaMenuHovered = false; 

// Select all top-level list items
const topLevelItems = document.querySelectorAll('.main-nav-wrapper #main-nav .nav-item');

// Define the delay duration
const delayDuration = 300; // You can adjust this value as needed

// Keep track of the currently hovered mega menu
let currentMegaMenu = null;


  





// Loop through each list item and set up hover animations
topLevelItems.forEach(item => {
  //const link = item.querySelector('a'); // Select the anchor tag
  const megaMenu = item.querySelector('.new-mega-menu-wrapper');

  // Add hover event listener to the list item
  item.addEventListener('mouseenter', () => {
    // If another mega menu is currently hovered, start fading it out with a delay
    console.log('hovered menu item');
    if (currentMegaMenu && currentMegaMenu !== megaMenu) {
      gsap.to(currentMegaMenu, {
        duration: 0.3,
        opacity: 0,
        visibility: 'hidden',
        zIndex: -1,
        ease: 'power1.out'
      });
    }

    // Show the current mega menu
    gsap.to(megaMenu, {
      duration: 0.3,
      opacity: 1,
      visibility: 'visible',
      zIndex: 100,
      ease: 'power1.out'
    });

    // Update the currently hovered mega menu
    currentMegaMenu = megaMenu;
  });

  // Add mouseleave event listener to the list item
  item.addEventListener('mouseleave', () => {
    // Hide the mega menu with a delay if it's not being hovered
    setTimeout(() => {
      if (!isMegaMenuHovered) {
        gsap.to(megaMenu, {
          duration: 0.3,
          opacity: 0,
          visibility: 'hidden',
          zIndex: -1,
          ease: 'power1.out'
        });
		resetTabs();
      }
    }, delayDuration);
  });

  // Add hover event listener to the mega menu
  megaMenu.addEventListener('mouseenter', () => {
    isMegaMenuHovered = true;
	isMegaMenuAreaHovered = true; // Mouse is within mega menu area
  });

  // Add mouseleave event listener to the mega menu
  megaMenu.addEventListener('mouseleave', () => {
    isMegaMenuHovered = false;
	isMegaMenuAreaHovered = false; // Mouse left mega menu area
    setTimeout(() => {
      if (!isMegaMenuHovered && !isMegaMenuAreaHovered) {
        gsap.to(megaMenu, {
          duration: 0.3,
          opacity: 0,
          visibility: 'hidden',
          zIndex: -1,
          ease: 'power1.out'
        });
		resetTabs();
      }
	  
    }, delayDuration);
  });
});



// Smooth scroll function using regular JavaScript
function smoothScroll(target, offset, duration) {
  const targetPosition = target - offset;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  const startTime = performance.now();

  function scrollStep(timestamp) {
      const currentTime = timestamp - startTime;
      const progress = Math.min(currentTime / duration, 1);
      const easedProgress = easeInOutCubic(progress);

      window.scrollTo(0, startPosition + distance * easedProgress);

      if (currentTime < duration) {
          requestAnimationFrame(scrollStep);
      }
  }

  requestAnimationFrame(scrollStep);
}

// Smooth scroll if there's a hash value in the URL
window.addEventListener("load", function () {
  if (window.location.hash) {
      const targetId = window.location.hash.substring(1); // Remove the '#' character
      const target = document.getElementById(targetId);

      if (target) {
          const offset = 32; // Adjust this offset to your preference

          smoothScroll(target.getBoundingClientRect().top + window.pageYOffset, offset, 1000); // 1000ms (1 second) duration
      }
  }
});

// Easing function
function easeInOutCubic(t) {
  if ((t /= 0.5) < 1) return 0.5 * t * t * t;
  return 0.5 * ((t -= 2) * t * t + 2);
}


  
  

// Old Mega Menu Hover Code
/*
const megaMenuWrapper = document.querySelector('.mega-menu');
const megaMenuParent = document.querySelector('.mega-menu-parent');

const tabs = megaMenuParent.querySelector('.tabs');
const panels = megaMenuParent.querySelector('.tabs-content');
const firstTab = megaMenuParent.querySelector('.tabs li:first-child');
const firstTabLink = firstTab.querySelector('a');
const firstPanel = panels.querySelector('.tabs-panel:first-child');

function resetTabs() {
	const activeTab = tabs.querySelector('.is-active');
	const activeTabLink = activeTab.querySelector('a');
	const activePanel = panels.querySelector('.is-active');

	activeTab.classList.remove('is-active');
	activePanel.classList.remove('is-active');
	activeTabLink.ariaSelected = "false";
	activePanel.ariaHidden = "false";

	firstTab.classList.add('is-active');
	firstTabLink.ariaSelected = "true";
	firstPanel.classList.add('is-active');
	firstPanel.ariaHidden = "true";
}

function showMegaMenu() {
	gsap.set(megaMenuWrapper, { display: 'block' });
	gsap.to(megaMenuWrapper, { duration: 0.5, opacity: 1, delay: 0.05 });
	setTimeout(() => {
		megaMenuParent.classList.add('is-active');
	}, 50);
}
  
function hideMegaMenu() {
	gsap.to(megaMenuWrapper, { duration: 0.5, opacity: 0, delay: 0.2, onComplete: () => {
		gsap.set(megaMenuWrapper, { display: 'none' });
	}});
	
	setTimeout(() => {
		megaMenuParent.classList.remove('is-active');
		resetTabs();
	}, 200);

	
}
  

//megaMenuParent.addEventListener('mouseenter', showMegaMenu);
//megaMenuParent.addEventListener('mouseleave', hideMegaMenu);


// Dropdown hover
const dropdownParent = document.querySelector('#main-nav .menu-item-has-children');
const dropdownMenu = dropdownParent.querySelector('.menu');

let timeoutID;

dropdownParent.addEventListener('mouseenter', () => {
	clearTimeout(timeoutID);
	timeoutID = setTimeout(() => {
		dropdownParent.classList.add('is-active');
		dropdownMenu.style.display = 'block';
	}, 50 );
	
});

dropdownParent.addEventListener('mouseleave', () => {
	timeoutID = setTimeout(() => {
		dropdownParent.classList.remove('is-active');
		dropdownMenu.style.display = 'none';
	}, 500 );
});
*/