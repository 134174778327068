// Newsletter Signup Form Label Animation on Focus

jQuery('.newsletter-signup form [type=text]').focusin( function() {
	jQuery(this).parent().parent().addClass('has-focus');
});

jQuery('.newsletter-signup form [type=text]').blur( function() {
	if( jQuery(this).val().length < 1) {
		jQuery(this).parent().parent().removeClass('has-focus');
	}
});



document.addEventListener('DOMContentLoaded', function(event) {
  gsap.utils.toArray('.tm-heading-wrapper.has-accent').forEach((el, index) => {
    const accentLine = el.querySelector('.accent-line');

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: 'top 80%',
        end: 'bottom 20%',
        
      }
    });

    tl.fromTo(accentLine, { width: '0px' }, { width: '188px', duration: 0.5, ease: 'back.out(4)' });
  });
});



document.addEventListener('DOMContentLoaded', function() {
	const tabContentContainers = document.querySelectorAll('.mm-tab-content');
  
	tabContentContainers.forEach(container => {
	  const tabPanels = container.querySelectorAll('.tabs-panel');
	  let tallestHeight = 0;
  
	  // Temporarily show each tab off-screen to calculate its height
	  tabPanels.forEach(panel => {
		panel.style.position = 'absolute';
		panel.style.visibility = 'hidden';
		panel.style.display = 'block';
  
		const panelHeight = panel.clientHeight;
		if (panelHeight > tallestHeight) {
		  tallestHeight = panelHeight;
		}
  
		// Reset styles after height calculation
		panel.style.position = '';
		panel.style.visibility = '';
		panel.style.display = '';
	  });
  
	  // Set the same height for all tab panels
	  tabPanels.forEach(panel => {
		panel.style.height = `${tallestHeight}px`;
	  });
	});
  });



// Reset tabs after function
// to be used on mouseenter and mouseleave function on mega menu script
const solutionsMM = document.querySelector('.mm-solutions');
if( solutionsMM ) {
  const tabs = solutionsMM.querySelector('.tabs');
  const panels = solutionsMM.querySelector('.tabs-content');
  const firstTab = solutionsMM.querySelector('.tabs li:first-child');
  const firstTabLink = firstTab.querySelector('a');
  const firstPanel = panels.querySelector('.tabs-panel:first-child');

  function resetTabs() {
    const activeTab = tabs.querySelector('.is-active');
    const activeTabLink = activeTab.querySelector('a');
    const activePanel = panels.querySelector('.is-active');

    activeTab.classList.remove('is-active');
    activePanel.classList.remove('is-active');
    activeTabLink.ariaSelected = "false";
    activePanel.ariaHidden = "false";

    firstTab.classList.add('is-active');
    firstTabLink.ariaSelected = "true";
    firstPanel.classList.add('is-active');
    firstPanel.ariaHidden = "true";
  }
} else {
  console.log("No mega menu found");
}


// Keep track of whether the mouse is within the mega menu area
let isMegaMenuAreaHovered = false;
let isMegaMenuHovered = false; 

// Select all top-level list items
const topLevelItems = document.querySelectorAll('.main-nav-wrapper #main-nav .nav-item');

// Define the delay duration
const delayDuration = 300; // You can adjust this value as needed

// Keep track of the currently hovered mega menu
let currentMegaMenu = null;



// Loop through each list item and set up hover animations
topLevelItems.forEach(item => {
  //const link = item.querySelector('a'); // Select the anchor tag
  const megaMenu = item.querySelector('.new-mega-menu-wrapper');

  // Add hover event listener to the list item
  item.addEventListener('mouseenter', () => {
    // If another mega menu is currently hovered, start fading it out with a delay
    if (currentMegaMenu && currentMegaMenu !== megaMenu) {
      gsap.to(currentMegaMenu, {
        duration: 0.3,
        opacity: 0,
        visibility: 'hidden',
        zIndex: -1,
        ease: 'power1.out'
      });
    }

    // Show the current mega menu
    gsap.to(megaMenu, {
      duration: 0.3,
      opacity: 1,
      visibility: 'visible',
      zIndex: 100,
      ease: 'power1.out'
    });

    // Update the currently hovered mega menu
    currentMegaMenu = megaMenu;
  });

  // Add mouseleave event listener to the list item
  item.addEventListener('mouseleave', () => {
    // Hide the mega menu with a delay if it's not being hovered
    setTimeout(() => {
      if (!isMegaMenuHovered && solutionsMM) {
        gsap.to(megaMenu, {
          duration: 0.3,
          opacity: 0,
          visibility: 'hidden',
          zIndex: -1,
          ease: 'power1.out'
        });
		resetTabs();
      }
    }, delayDuration);
  });

  // Add hover event listener to the mega menu
  megaMenu.addEventListener('mouseenter', () => {
    isMegaMenuHovered = true;
	isMegaMenuAreaHovered = true; // Mouse is within mega menu area
  });

  // Add mouseleave event listener to the mega menu
  megaMenu.addEventListener('mouseleave', () => {
    isMegaMenuHovered = false;
	isMegaMenuAreaHovered = false; // Mouse left mega menu area
    setTimeout(() => {
      if (!isMegaMenuHovered && !isMegaMenuAreaHovered && solutionsMM) {
        gsap.to(megaMenu, {
          duration: 0.3,
          opacity: 0,
          visibility: 'hidden',
          zIndex: -1,
          ease: 'power1.out'
        });
		resetTabs();
      }
	  
    }, delayDuration);
  });
});